import React from 'react';
import { Link as JSSLink } from '@sitecore-jss/sitecore-jss-react';

// link wrapper because sitecore jss's Link doesn't support querystring until v20
// we can't update to v20 with our current version of sitecore

const Link = ({ field, ...props }) => {
  if (!(field && field.value)) {
    return null;
  }

  let finalHref = field.value.href;

  if (field.value.querystring) {
    if (!field.value.querystring.startsWith('?')) {
      finalHref += '?';
    }
    finalHref += field.value.querystring;
  }

  if (field.value.linktype !== 'anchor' && field.value.anchor) {
    if (!field.value.anchor.startsWith('#')) {
      finalHref += '#';
    }
    finalHref += field.value.anchor;
  }

  return (
    <JSSLink
      {...props}
      field={{
        ...field,
        value: { ...field.value, href: finalHref },
      }}
    />
  );
};

export default Link;
